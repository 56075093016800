import React from "react"

import { Button } from "@launchacademy/voyager"

import "@launchacademy/voyager/css/buttons/buttons.css"
import useSyllabusModal from "./hooks/useSyllabusModal.js"

const SyllabusModalButton = ({ location, className = "", size, icon, secondary, placement }) => {
  const {
    modal: syllabusModal,
    staticForm,
    setModalVisibility: setSyllabusModalVisibility,
  } = useSyllabusModal({ location })
  return (
    <>
      <Button
        to="#syllabusDialog"
        state={{ showSyllabus: true }}
        icon={icon}
        onClick={(e) => {
          e.preventDefault()
          setSyllabusModalVisibility(true)
        }}
        secondary={secondary}
        placement={placement}
        className={`${className}`}
        size={size}
      >
        Get the Syllabus
      </Button>

      {syllabusModal}
    </>
  )
}

export default SyllabusModalButton
