import * as React from "react"

import { faChevronRight, faPhone, faEye } from "@fortawesome/free-solid-svg-icons"
import {
  Quote,
  Button,
  SimpleBanner,
  BannerTitle,
  TitledParagraph,
  CardList,
  ReviewList,
  AccentCircle,
} from "@launchacademy/voyager"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import _ from "lodash"

import Configuration from "../../Configuration"
import { Article } from "../../components/alumniNews/Article.tsx"
import { Award } from "../../components/awards/Award"
import BookInfoCallButton from "../../components/infoCall/BookInfoCallButton"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SyllabusModalButton from "../../components/syllabus/SyllabusModalButton"
import { reviews, columns, projectContent } from "../../pageData/successStoriesData"
import buildImage from "../../services/buildImage"

import "./css/success-stories.css"

const SuccessStories = ({ data, location }) => {
  const launcherStories = (data?.articles?.nodes || []).map((article) => {
    return (
      <Article
        key={article.slug}
        path={`/${article.slug}`}
        Image={buildImage(article.image?.localFile)}
        title={_.truncate(article.title, { length: 30 })}
      />
    )
  })

  const projects = projectContent.map((project) => {
    return {
      ...project,
      Image: (props) => (
        <img src={project.imageUrl.toString()} alt={`${project.name} Screenshot`} {...props} />
      ),
    }
  })
  return (
    <Layout>
      <Seo
        title="Launchers share their success as professional software developers"
        pathname={location.pathname}
        description="Hear perspective from Launch Academy's alumni. They'll share how they launched their Software Development careers."
      />
      <section className="hero">
        <div className="hero__image-column_square">
          <div className="hero__image-container_square">
            <GatsbyImage
              image={getImage(data.headerImage)}
              className="hero__image_square hero_image_sm"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">Launch your career.</h2>
          <h1 className="hero__image_title">Putting graduates front and center</h1>
          <div className="hero__button-container">
            <BookInfoCallButton
              location={location}
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              size="lg"
            />
          </div>
          <div className="hero__button-container">
            <SyllabusModalButton
              location={location}
              text="Get the Syllabus"
              icon={faEye}
              className="hero__button"
              secondary
              size="lg"
            />
          </div>
          <Quote
            quote="I absolutely loved my time during the program. It felt so good to be a student again and focus on learning and absorbing as much as possible.  The curriculum was both challenging and engaging. We were constantly putting our learning into practice, which made the experience so much more satisfying and meaningful.  My biggest takeaway from Launch Academy is that we were really learning how to learn.  What I mean by that is we were learning a very specific stack of languages and technologies that would not be used in every position, but in doing so we were gaining the skills needed to learn any technology or programming language."
            name="Maeghan Provencher"
            company="Bowst"
          />
        </div>
      </section>

      <section className="mb-20 constrained-container">
        <TitledParagraph title="Alumni in the News" />

        <div className="article-list article-list__middle-emphasis">{launcherStories}</div>
      </section>

      <SimpleBanner
        type="prose"
        style={{ maxHeight: 700 }}
        BackgroundImage={buildImage(data.codeNames)}
      >
        <AccentCircle color="secondary" width="32" className="top-8 md:left-32 md:top-28" />
        <BannerTitle>Learn more about Launch Academy’s immersive coding program</BannerTitle>
        <div className="banner__button-container z-10">
          <SyllabusModalButton
            location={location}
            placement="photo"
            text="Get the Syllabus"
            to="#"
            secondary
            icon={faEye}
          />
        </div>
      </SimpleBanner>

      <section className="card-list-container my-20">
        <h2 className="text-left md:text-center mx-4">Student Projects</h2>
        <CardList content={projects} numberOfColumns={columns} />
      </section>

      <section className="bg-gray-cloud p-10">
        <TitledParagraph title="See why past grads love Launch Academy">
          <p>Get inspired by these stories.</p>
        </TitledParagraph>

        <div className="award-list">
          <Award
            key="coureReport2017"
            Image={buildImage(data.courseReport2017)}
            altText="Best Bootcamp Award: Course Report 2017"
          />
          <Award
            key="courseReport2018"
            Image={buildImage(data.courseReport2018)}
            altText="Best Bootcamp Award: Course Report 2018"
          />
          <Award
            key="courseReport2019"
            Image={buildImage(data.courseReport2019)}
            altText="Best Bootcamp Award: Course Report 2019"
          />
          <Award
            key="courseReport2020"
            Image={buildImage(data.courseReport2020)}
            altText="Best Bootcamp Award Course Report 2020-2021"
          />
          <Award
            key="switchUp2020"
            Image={buildImage(data.switchUp2020)}
            altText="Best Bootcamp Award: SwitchUp 2020"
          />
          <Award
            key="courseReport2021"
            Image={buildImage(data.courseReport2021)}
            altText="Best Bootcamp Award Course Report 2021-2022"
          />
          <Award
            key="courseReport2023"
            Image={buildImage(data.courseReport2023)}
            altText="Best Bootcamp Award Course Report 2023"
          />
        </div>

        <section className="review-grid-container mt-20">
          <ReviewList allReviews={reviews} />
          <div className="review-grid__button">
            <Button text="Visit us on Course Report" to="#" />
            <Button text="Visit us on SwitchUp" to="#" />
          </div>
        </section>
      </section>

      <section className="bg-secondary-brand">
        <div className="constrained-container hero">
          <div className="hero__supporting-content p-10">
            <h2 className="hero__image_title pb-10">
              Are you ready to join our network of 1,000+ outstanding alumni? Join Launch Academy
            </h2>
            <div className="hero__button-container">
              <BookInfoCallButton
                text="Schedule an Info Call"
                icon={faPhone}
                className="banner__button"
                location={location}
                size="lg"
              />
            </div>
            <div className="hero__button-container">
              <SyllabusModalButton
                location={location}
                to="#"
                text="Get the Syllabus"
                icon={faChevronRight}
                className="banner__button button_secondary"
                size="lg"
              />
            </div>
          </div>
          <div className="hero__image-column">
            <div className="hero__image-container">
              <GatsbyImage
                image={getImage(data.trioOnFloor)}
                alt="Trio on Floor Coding"
                className="hero__image hero__image_sm"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/demoing-green-shirt/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 700])
      }
    }
    codeNames: file(relativePath: { regex: "/codenames/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [640, 768, 1024, 1280, 1536])
      }
    }
    courseReport2017: file(relativePath: { regex: "/course-report-2017/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    courseReport2018: file(relativePath: { regex: "/course-report-2018/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    courseReport2019: file(relativePath: { regex: "/course-report-2019/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    courseReport2020: file(relativePath: { regex: "/course-report-2020/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    courseReport2021: file(relativePath: { regex: "/course-report-2021/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    courseReport2023: file(relativePath: { regex: "/course-report-2023/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 225)
      }
    }
    switchUp2020: file(relativePath: { regex: "/switchup-2020/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, height: 225)
      }
    }
    trioOnFloor: file(relativePath: { regex: "/trio-on-floor/" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    articles: allStrapiArticle(
      limit: 3
      filter: { category: { slug: { eq: "launcher-stories" } } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        id
        title
        slug
        publishedAt
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                breakpoints: [210, 630]
                transformOptions: {}
                width: 630
                aspectRatio: 1.5
              )
            }
          }
        }
      }
    }
  }
`

export default SuccessStories
