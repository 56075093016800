/* eslint-disable react/no-unknown-property */
import React from "react"

import { useForm } from "react-hook-form"

import Configuration from "../../Configuration"

const SyllabusRequestForm = ({ location, onSubmit, className = "" }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      "la-form-name": "syllabus",
    },
  })

  const isPartTimeOnline = location?.pathname && location.pathname.includes("/part-time-online")

  return (
    <form
      data-netlify="true"
      name="syllabus"
      autoComplete="off"
      noValidate="noValidate"
      onSubmit={handleSubmit(onSubmit)}
      className={`form w-full ${className}`}
      netlify-honeypot="emailAddress"
    >
      <div className="form__control">
        <label className="form__label" htmlFor="program">
          Program
        </label>
        <select
          className={`form__input w-full ${errors.program ? "form__input_error" : ""}`}
          type="program"
          id="program"
          {...register("program", { required: true })}
        >
          <option selected={!isPartTimeOnline} value="immersiveOnsite">
            Full-Time Immersive
          </option>
          <option selected={isPartTimeOnline} value="partTimeOnline">
            Part-Time Online
          </option>
        </select>
        {errors.program && <p className="form__error">Please choose a program</p>}
        <label className="form__label" htmlFor="email">
          Email
        </label>
        <input
          autoFocus
          className={`form__input w-full ${errors.email ? "form__input_error" : ""}`}
          type="email"
          id="email"
          {...register("email", { required: true, pattern: Configuration.emailRegex })}
        />
        {errors.email && <p className="form__error">Invalid email</p>}
      </div>

      <input type="submit" value="Send" className="button button_size_sm" />
      <input type="hidden" value="syllabus" {...register("form-name")} />
      <input type="hidden" value="syllabus" {...register("la-form-name")} />
      <p hidden>
        <label>
          ignore: <input value="" {...register("emailAddress", { validate: (v) => v === "" })} />
        </label>
      </p>
    </form>
  )
}

export default SyllabusRequestForm
